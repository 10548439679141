import { shouldPolyfill as shouldPolyfillIntlLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillIntlRelativeTimeFormat } from "@formatjs/intl-relativetimeformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillIntlPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";

export async function applyPolyfills() {
	if (typeof globalThis === "undefined") {
		(window as any).globalThis = window;
	}

	if (typeof Element.prototype.toggleAttribute === "undefined") {
		Element.prototype.toggleAttribute = function (name: string, force: boolean) {
			if (force !== void 0) force = !!force;

			if (this.hasAttribute(name)) {
				if (force) return true;

				this.removeAttribute(name);
				return false;
			}
			if (force === false) return false;

			this.setAttribute(name, "");
			return true;
		};
	}

	if (typeof String.prototype.replaceAll === "undefined") {
		String.prototype.replaceAll = (await import("string.prototype.replaceall")).default;
	}

	if (typeof String.prototype.matchAll === "undefined") {
		String.prototype.matchAll = (await import("string.prototype.matchall")).default;
	}

	if (typeof Array.prototype.flat === "undefined") {
		Array.prototype.flat = (await import("array.prototype.flat")).default;
	}

	if (typeof Array.prototype.flatMap === "undefined") {
		Array.prototype.flatMap = (await import("array.prototype.flatmap")).default;
	}

	if (typeof queueMicrotask === "undefined") {
		globalThis.queueMicrotask = (await import("queue-microtask")).default;
	}

	if (typeof Object.fromEntries === "undefined") {
		Object.fromEntries = (await import("object.fromentries")).default;
	}

	if (typeof requestIdleCallback === "undefined") {
		await import("requestidlecallback");
	}

	if (shouldPolyfillIntlLocale()) {
		await import("@formatjs/intl-locale/polyfill");
	}

	if (shouldPolyfillIntlPluralRules()) {
		await import("@formatjs/intl-pluralrules/polyfill");
		await import(`@formatjs/intl-pluralrules/locale-data/en`);
	}

	if (shouldPolyfillIntlRelativeTimeFormat()) {
		await import("@formatjs/intl-relativetimeformat/polyfill-force");
		await import(`@formatjs/intl-relativetimeformat/locale-data/en`);
	}

	const shouldPolyfillEventTarget = (function () {
		try {
			new globalThis.EventTarget();
		} catch (error) {
			return true;
		}
		return false;
	})();

	if (shouldPolyfillEventTarget) {
		await import("event-target-polyfill");
	}

	const shouldPolyfillMatchMedia = (function () {
		const mediaQuery = globalThis.matchMedia("");
		return mediaQuery?.addEventListener == null;
	})();

	if (shouldPolyfillMatchMedia) {
		const originalImplementation = globalThis.matchMedia;
		globalThis.matchMedia = function (query: string): MediaQueryList {
			const result = originalImplementation.call(this, query);

			result.addEventListener = function (_: string, listener: EventListenerOrEventListenerObject) {
				result.addListener.call(this, listener as never);
			};
			result.removeEventListener = function (_: string, listener: EventListenerOrEventListenerObject) {
				result.removeListener.call(this, listener as never);
			};

			return result;
		};
	}

	if (typeof ResizeObserver === "undefined") {
		globalThis.ResizeObserver = (await import("@juggle/resize-observer")).ResizeObserver;
	}

	if (typeof Element.prototype.animate === "undefined") {
		await import("web-animations-js");
	}
}
